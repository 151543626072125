import './app/style.css'
import React, { Suspense, lazy } from 'react'
import ReactDOM from 'react-dom/client'
import DashboardBackground from '#/components/containers/DashboardBackground'
import { SiteLoaderPage } from '#/pages/SiteLoaderPage'

const LazyApp = lazy(() => import('./app.main'))

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <DashboardBackground>
      <Suspense fallback={<SiteLoaderPage />}>
        <LazyApp />
      </Suspense>
    </DashboardBackground>
  </React.StrictMode>
)
