import { PulsingText } from '#/components/loadingStates/PulsingText'
import { SkeletonLines } from '#/components/loadingStates/SkeletonLines'
import useBreakpoint from '#/hooks/useBreakpoint'

const FakeHeader = () => {
  const HeaderHeight = useBreakpoint({ base: '56px', sm: '48px' })
  const margin = useBreakpoint({ base: '8px', sm: '8px' })
  return (
    <div className={`flex h-fit w-full flex-col bg-gray-900`}>
      <div
        style={{ height: HeaderHeight, paddingRight: margin, paddingLeft: margin }}
        className="grow flex flex-row items-center justify-between py-[6px] lg:mt-0"
      >
        <div className="flex items-center">
          <SkeletonLines lines={1} />
        </div>
      </div>
    </div>
  )
}

export const SiteLoaderPage = () => {
  return (
    <div className="w-full h-screen flex flex-col">
      <div className={`flex bg-gray-1000 h-fit w-full flex-col`}>
        <FakeHeader />
      </div>
      <div className="flex flex-col grow justify-center">
        <div className="flex flex-row justify-center">
          <div className="relative inline-flex">
            <PulsingText className="text-6xl" />
          </div>
        </div>
      </div>
    </div>
  )
}
