const copy = `oku.trade`
export const PulsingText = ({ className }: { className: string }) => {
  return (
    <div className={className}>
      <span className="absolute mx-auto py-4 flex border w-fit text-white bg-gradient-to-r blur-xl from-blue-500 via-teal-500 to-pink-500 bg-clip-textbox-content font-extrabold text-transparent text-center select-none">
        {copy}
      </span>
      <h1 className="relative top-0 w-fit h-auto py-4 justify-center flex text-white  items-center bg-clip-text font-extrabold text-transparent text-center select-auto">
        {copy}
      </h1>
    </div>
  )
}
